const config = {

	app_name: "Tessera Digitale - CRAL Menarini",

	lang: "it",

	bootstrap: true,
	onsen: false,
	pwa: true,
	onlypwa: false,

	auth_enabled: false,
	api_endpoint: "https://cralmenarini.noemultimedia.eu/api/1/",
	api_endpoint_dev: "http://localhost:8001/api/1/",
	assetsurl: "",

	indexedDBStorage: true,


	password_reset_url: "",
	terms_url: "",
	privacy_url: "",
}


try {
	module.exports = config;
} catch (e) {
}
