function installScreen() {

	var browser = bowser.getParser(window.navigator.userAgent);

	console.log('browser', browser);

	// if ((browser.isOS('ios') || browser.isOS('macos')) && (navigator.maxTouchPoints != 0)) {
	if ((browser.isOS('ios') || browser.isOS('macos'))) {

		if (browser.isBrowser('safari')) {

			console.log('IOS + SAFARI + TOUCH');

			getTmpl('templates/installpwa-apple', {}, '#boxPopupInfoInstall', bindPopupInfoInstalleriOS);

		} else {

			console.log('IOS + OTHER BROWSER + TOUCH  -> COPY LINK');

			// getTmpl('templates/installpwa-apple-browser-required', {}, 'body', copyLinkPWA);
			getTmpl('templates/installpwa-apple-browser-required', {}, '#boxPWA', copyLinkPWA);

		}

	} else {

		if (browser.isEngine('blink')) {

			console.log('OTHER SYSTEM + CHROME');

			$('#downloadPWAOtheriOS').show();
			$('#infoPopupInfoInstalleriOS').hide();

			$('#downloadPWAOtheriOS').off();
			$('#downloadPWAOtheriOS').on({
				click: function () {
					getTmpl('templates/installpwa', {}, 'body', function () {

						$('#installPWA').off();
						$('#installPWA').on({
							click: function () {
								installPWA();
							}
						});

					});
				}
			});

			continueBrowser();


		} else {

			console.log('OTHER SYSTEM + OTHER BROWSER -> COPY LINK');

			// getTmpl('templates/installpwa-blink-browser-required', {}, 'body', copyLinkPWA);
			getTmpl('templates/installpwa-blink-browser-required', {}, '#boxPWA', copyLinkPWA);

		}

	}

}

function bindPopupInfoInstalleriOS() {

	$("#boxPopupInfoInstall").addClass('show');
	$("#boxPopupInfoInstall").css('display', 'flex');

	$('#closePopupInfoInstalleriOS').off();
	$('#closePopupInfoInstalleriOS').on({
		click: function () {
			$("#boxPopupInfoInstall").hide()
		}
	});

	$('#openPopupInfoInstalleriOS').off();
	$('#openPopupInfoInstalleriOS').on({
		click: function () {
			$("#boxPopupInfoInstall").show()
		}
	});

	continueBrowser();

}

function copyLinkPWA() {

	$('#copyLink').off();
	$('#copyLink').on({
		click: function () {
			navigator.clipboard.writeText(location.href).then(function () {
				dialog.alert(lang['alertcopylink']);
			});
		}
	});

	continueBrowser();
}

function continueBrowser() {

	$('.continueBrowser').off();
	$('.continueBrowser').on({
		click: function () {
			$('#boxPWA, #boxPopupInfoInstall').hide();
			$('#signInFormBox').addClass('loginBrowser');
			$('#signInForm').show();
		}
	});

}


function initApp() {

	// Push notifications
	// registerPush();

	// Accelerometer
	// accel = navigator.accelerometer.watchAcceleration(accelerometerSuccess, accelerometerError, accelerometerOptions);

	// Compass
	// orient = navigator.compass.watchHeading(compassSuccess, compassError, compassOptions);

	// INSOMNIA MODE
	if (typeof (window.plugins) == 'object') {
		if (typeof (window.plugins.insomnia) == 'object') {
			window.plugins.insomnia.keepAwake();
		}
	}

	if (typeof (window.navigationbar) == 'object') {
		// Set android navigationbar to auto hide
		window.navigationbar.setUp(true);
	}

	if (typeof (window.StatusBar) == 'object') {
		// hide the status bar
		window.StatusBar.hide();
	}

	// if (typeof (window.screen.orientation) == 'object') {

	// 	if (typeof (window.screen.orientation.lock) == 'function') {
	// 		// Lock & allow both landscape modes
	// 		try {
	// 			window.screen.orientation.lock('landscape');
	// 		} catch (e) {
	// 			console.log(e);
	// 		}
	// 	}

	// }

	auth.callback = function () {
		frontend.startApp();
	}

	auth.init();

}

function onDeviceReady(platform) {


	// Platform check
	if (platform == 'browser') {

		/***
		var browser = bowser.getParser(window.navigator.userAgent);
		
		if(!browser.satisfies({
			chrome:'>=28',
			edge:'>=74',
			
		})){
			window.location.href = "supported-browsers.html";
			return;
		}
		***/

		if (config.pwa && 'serviceWorker' in navigator) {

			if (config.onlypwa) {

				if (!isStandalone()) {

					navigator.serviceWorker.getRegistrations().then(registrations => {
						if (!registrations.length) {

							navigator.serviceWorker.register('install-worker.js', { scope: './' }).then(function (reg) {

								installScreen();

							});

						} else {

							installScreen();

						}
					});

					return;

				}
			}


			var appIsUpdating = false;

			navigator.serviceWorker.register('service-worker.js', { scope: './' }).then(function (reg) {

				//				console.log('Service worker registered', reg);
				//				var serviceWorker;
				//				if (reg.installing) {
				//					serviceWorker = reg.installing;
				//				} else if (reg.waiting) {
				//					serviceWorker = reg.waiting;
				//				} else if (reg.active) {
				//					serviceWorker = reg.active;
				//				}
				//				
				//				if (serviceWorker) {
				//					serviceWorker.addEventListener('statechange', function (e) {
				//						console.log("[SERVICEWORKER] state", e.target.state);
				//					});
				//				}

				navigator.serviceWorker.addEventListener('message', function (event) {
					// console.log("SERVICE WORKER EVENT", event);
					if (event.data == 'AppCacheUpdated') {
						if (appIsUpdating) {
							location.reload();
						}
					}
				});


				navigator.serviceWorker.ready.then(function () {
					initApp();
				});


				reg.addEventListener('updatefound', function () {

					console.log("newWorker FOUND");

					appIsUpdating = true;
					$('#pwaUpdate').show();

					/*****
					// A wild service worker has appeared in reg.installing!
					const newWorker = reg.installing;

					// newWorker.state;
					// "installing" - the install event has fired, but not yet complete
					// "installed"  - install complete
					// "activating" - the activate event has fired, but not yet complete
					// "activated"  - fully active
					// "redundant"  - discarded. Either failed install, or it's been
					//                replaced by a newer version

					newWorker.addEventListener('statechange', function(){
						console.log('newWorker.state has changed', newWorker.state);
						
						if(newWorker.state == 'activated'){
							
						}
					});
					*****/

				});

			}, function (error) {

				console.log("Service worker not registered", error);
				initApp();

			});

			navigator.serviceWorker.addEventListener('controllerchange', function () {
				// This fires when the service worker controlling this page
				// changes, eg a new worker has skipped waiting and become
				// the new active worker.

				console.log('controllerchange EVENT');

			});


		} else {
			initApp();
		}

	} else {
		initApp();
	}

}

function onResume() {
	// Handle the resume event
}


if (typeof (cordova) == 'object') {
	// Cordova deviceready 
	document.addEventListener("deviceready", onDeviceReady(device.platform), false);

	// Cordova resume
	document.addEventListener("resume", onResume, false);

} else {
	// Jquery documentready
	$(document).ready(function () {
		onDeviceReady('browser');
	});
}




