frontend = {
	
	startApp: function() {
		
		// Onsen specific startup functions
		if(config.onsen){
			// ons.disableAutoStatusBarFill();
			// ons.platform.select('ios');
		}
		
		// Bootstrap specific startup functions
		if(config.bootstrap){
			
		}
		
		// Disable back button #1 - Discard previous page
		history.pushState(null, document.title, location.href);
		
		$(window).on({
		
			// Disable back button #2 - intercept history back
			'popstate': function(e){
				
				history.pushState(null, document.title, location.href);
				return false;
				
			},
			
			// If there is an outologiut timer, logout when closing
			'unload': function(){
				
				if(config.auth_enabled){
					if(parseInt(auth.user.groups_settings.autologout) > 0){
						auth.deauth();
						// console.log("DEAUTHED");
					}
				}
			}
			
//			'resize': function(){
//				
//			},
			
		});
		
		$(document).off('assets.download');
		$(document).on({
		
			'assets.download':function(event, data){
				
				// console.log("ASSETS DOWNLOAD DATA", data);
				
				var downloaded = (assets.downloaded / 1000000).toFixed(2).toString();
				var downloadsize = (assets.downloadsize / 1000000).toFixed(2).toString() + ' MB';
				
				/**
				var progresstxt = '.downloadProgress[data-id='+data.flowID+']';
				var progressbar = '.downloadIndicator[data-id='+data.flowID+']';
				var progressfill = '.downloadIndicator[data-id='+data.flowID+'] > .downloadCompleted';
				**/
				
				var progresstxt = '.downloadProgress';
				var progressbar = '.downloadIndicator';
				var progressfill = '.downloadIndicator > .downloadCompleted';
				
				if(downloaded == 0){
					$(progresstxt).html('Getting package list...');
				} else {
					
					if(assets.downloaded < assets.downloadsize){
						$(progressbar).show();
						var pct = (assets.downloaded / assets.downloadsize) * 100;
						$(progressfill).css({
							width: pct+'%',
						})
						$(progresstxt).html(downloaded+' / '+downloadsize);
					} else {
						$(progresstxt).html('');
						$(progressbar).hide();
					}
					
				}
				
				
			}
			 
		});
		
		
		
		$(document).off('keyboardDidHide');
		$(document).on({
		
			'keyboardDidHide':function(){
				//console.log("Keyboard did hide");
				window.scrollTo(0,0);
			}
			 
		});
		
		// var startPromises = [
		// 	assets.getLocalFS(),
		// 	frontend.refreshUser(),
		// ];
		
		// Promise.all(startPromises).then(function(){
			
		// 	// console.log("APP STARTED");
		// 	if(config.auth_enabled){
		// 		if(auth.user.groups_settings.autologout){
		// 			var timeout = parseInt(auth.user.groups_settings.autologout);
		// 			if(timeout > 0){
		// 				frontend.autologout(timeout);
		// 			}
		// 		}
			
		// 	} else {
			
		// 	}
			
		// });

		card.init();

	},
	
	load: function(page, action, template, data={}, callback=null){
		
		if(action){
		
		  	postData(action, data, function(response){
		  		
		  		if(template){
			  		getTmpl(template, response, '#main', function(){
			  			// console.log("GOT TEMPLATE", template);
			  			// frontend.templateReady(template, response, callback);
			  			
			  			if(typeof(callback)=='function'){
			  				callback(response);
			  			}
			  			
			  		});
		  		} else {
		  			if(typeof(callback)=='function'){
		  				callback(response);
		  			}
		  		}
		  		
		  	});
		  	
		} else {
			
			if(template){

				getTmpl(template, data, '#main', function(){
			  		// console.log("GOT TEMPLATE", template);
			  		// frontend.templateReady(template, data, callback);
			  		
			  		if(typeof(callback)=='function'){
		  				callback();
		  			}
			  	});
		  	
		  	} else {
		  		
		  		if(typeof(callback)=='function'){
	  				callback();
	  			}	
		  		
		  	}
			
		}
	},
	
	closeSessionTimeout: null,
	
	autologout: function(seconds){
		
		frontend.resetAutologout(seconds);
		
		$(window).off('mousedown mousemove mouseup touchstart touchmove touchend');
		$(window).on('mousedown mousemove mouseup touchstart touchmove touchend', function(){
			frontend.resetAutologout(seconds);
		});
		
	},
	
	resetAutologout: function(seconds){
	
		if(frontend.closeSessionTimeout){ clearTimeout(frontend.closeSessionTimeout); };
		
		frontend.closeSessionTimeout = setTimeout(function(){
			clearTimeout(frontend.closeSessionTimeout);
			$(window).off('mousedown mousemove mouseup touchstart touchmove touchend');
			
			// Close player if is open
			$('#playerFrame').attr('src', '');
			$('#player').hide();
			
			// Automatically deauth
			auth.deauth();
			
		}, seconds*1000);
		
	},
	
	refreshUser: function(){
		return new Promise(function(resolve, reject){
			
			if(navigator.onLine){
				postData('getMe', {}, function(response){
					//console.log('USER', response.user);
					if(response.status == 'KO'){
						auth.deauth();
					} else {
						auth.refresh(response.user, function(){
							resolve();
						});
					}
				}, function(){
					resolve();
				});
			} else {
				resolve();
			}
			
		});
	},
	
	getSupport: function(){
	
		var platform = cordova.platformId;
		try{
			var electron = require("electron");
			platform = "electron";
		} catch(e){
		
		}
	
		var link = "mailto:support@noe.fi.it"
		+ "?subject=" + encodeURIComponent("QuickconPlayer support request")
		+ "&body="
		+ encodeURIComponent("Platform: "+platform+", "+cordova.platformVersion+", "+bowser.getParser(navigator.userAgent).getOSName()+"\r\n")
		+ encodeURIComponent("User: "+auth.user.id+", "+auth.user.email+", "+auth.user.role+"\r\n")
		+ encodeURIComponent("\r\nPlease specify any relevant detail about your issue:\r\n\r\n")
	
		try{
			electron.shell.openExternal(link);
		} catch(e) {
			cordova.InAppBrowser.open(link, '_system');
		}
		
	},
	
	settingsCallback: function(){
		
		$('.changePassword').off();
		$('.changePassword').on({
			click:function(){
				
				if(validateForm('#newPasswordForm')){
					
					var newpassword = $('#newpassword').val();
					var newpassword_confirm = $('#newpassword').val();
					
					if(newpassword == newpassword_confirm){
						
						postData('changepassword', {
							newpassword: newpassword,
							newpassword_confirm: newpassword_confirm,
						}, function(response){
							if(response.status == 'OK'){
							
								auth.refresh(response.user, function(){
									
									auth.saveCredentials(auth.saveCredentials(auth.user.username, newpassword));
									
									dialog.alert({
										title:'SUCCESS',
										message:'Password succesfully updated',
									}).then(function(){
										frontend.load('settings', 'getSettings', 'templates/settings');
									});
									
								});
								
							} else {
								dialog.alert(response.message);
							}
						});
						
					} else {
						$('#newpassword_confirm').get(0).setCustomValidity("Passwords do not match!");
						$('#newpassword_confirm').get(0).reportValidity();
					}
					
				}
				
			}
		});
		
		$('.logout').off();
		$('.logout').on({
			click:function(){
				
				frontend.deauth();
				
			}
		});

	},
	
	deauth: function(){
		dialog.confirm({
			title: "LOGOUT",
			message: "Are you sure to log out?<br />You will need to insert your credentials to log back in.",
		}).then(function(btnIndex){
			if(btnIndex == 1){
				auth.deauth();
			}
		})
	},
	
}
