// requires: jquery, localforage, utils

auth = {

	enabled: config.auth_enabled,

	endpoint: null,

	preauth: {
		user: null,
		password: null
	},

	setEndpoint: function () {

		var url = new URL(document.location.href);

		auth.endpoint = config.api_endpoint;

		if (document.location.hash == "#dev") {
			auth.endpoint = config.api_endpoint_dev;
		}

	},

	db: null,

	user: null,

	callback: null,

	init: function () {

		auth.setEndpoint();

		auth.db = localforage.createInstance({ name: 'auth' });

		if (auth.enabled) {

			auth.db.getItem("user").then(function (user) {

				if (user != null) {
					console.log("found user", user);

					auth.user = user;

					if (typeof (auth.callback) == 'function') {
						auth.callback();
					}
				} else {

					if (auth.preauth.user && auth.preauth.password) {
						auth.getToken(auth.preauth.user, auth.preauth.password);
					} else {
						auth.tokenForm();
					}

				}

			});

		} else {
			auth.callback();
		}

	},


	refresh: function (userUpdate, callback) {

		auth.user = userUpdate;

		auth.db.getItem("user").then(function (user) {

			// IMPORTANT: mantain saved token when updating only user preferences

			if (user != null) {

				if (!userUpdate.token) {
					userUpdate.token = user.token
				}

			}

			auth.db.setItem('user', userUpdate).then(function () {

				if (typeof (callback) == 'function') {
					callback();
				}

			}).catch(function (error) {

				console.log(error);

			});



		});

	},

	tokenForm: function () {

		// TODO REMOVE THIS!
		frontend.load('login', null, 'templates/tokenForm', {}, function () {

			if (typeof (window.plugins) == 'object') {
				if (window.plugins.touchid) {
					window.plugins.touchid.isAvailable(function () {

						window.plugins.touchid.has(auth.endpoint, function () {
							// alert("Touch ID avaialble and Password key available");
							window.plugins.touchid.verify(auth.endpoint, "Unlock your credentials", function (credentials) {

								//alert("Tocuh " + password);
								var credentials = JSON.parse(credentials);

								// auth.fillCredentials(credentials.u, credentials.p);
								auth.getToken(credentials.u, credentials.p);

							});

						}, function () {

							auth.getCredentials();

						});

					}, function () {

						auth.getCredentials();
					});

				}
			} else {
				auth.getCredentials();
			}

			$('#submitAuth').off();
			$('#submitAuth').on({
				click: function () {
					if (navigator.onLine) {
						auth.getToken(null, null, true);
					} else {
						dialog.alert("An active network connection is required to authenticate!");
					}
				}
			});

			$('#cancelAuth').off();
			$('#cancelAuth').on({
				click: function () {
					$('#tokenForm').hide();
				}
			});

			$(document).off('keyup');
			$(document).on({
				keyup: function (e) {
					console.log(e);
					if (e.originalEvent.keyCode == 13) {
						if (navigator.onLine) {
							auth.getToken(null, null, true);
						} else {
							dialog.alert("An active network connection is required to authenticate!");
						}
					}
				}
			});

		});

	},

	getToken: function (username, password, savecredentials) {

		username = username || $('#username').val();
		password = password || $('#password').val();

		$('#signInForm').addClass('loading');

		postData('getToken', {
			username: username,
			password: password,
		}, function (response) {

			if (response.status == 'OK') {

				// Unbind enter key triggering login
				$(document).off('keyup');

				// Implement re-generated credentials save prompt after login

				if (response.newpassword) {

					var message = 'As this is your first login, we set up a new secure password for you.<br />';
					message += 'Your new password is:<br /><br />';
					message += '<b id="newUserPassword" title="click to copy">';
					message += response.newpassword;
					message += ' <span class="mdi mdi-content-copy"></span>';
					message += '</b>';
					message += '<br /><br />Save it now on your favourite keychain, or go ahead and accept the prompt if you want to save it on this device for future use.';



					dialog.alert({
						title: 'FIRST LOGIN',
						message: message,
						buttonLabels: 'OK, GO AHEAD!'
					}).then(function () {

						if (savecredentials) {
							auth.saveCredentials(username, response.newpassword);
						}

					});

					$('#newUserPassword').off();
					$('#newUserPassword').on({
						click: function () {
							navigator.clipboard.writeText(response.newpassword).then(function () {
								$('#newUserPassword').addClass('text-success');
							});
						}
					});


				} else {

					if (savecredentials) {
						// FIXME iOS safari PWA error FIXME
						auth.saveCredentials(username, password);
					}

				}



				auth.refresh(response.user, function () {
					auth.callback();
				});


			} else {

				console.log(response);
				dialog.alert(response.message).then(function () {
					$('#signInForm').removeClass('loading');
				});

			}

		});

	},


	saveCredentials: function (username, password) {

		if (typeof (window.plugins) == 'object') {

			window.plugins.touchid.isAvailable(function () {
				auth.saveTouchCredentials(username, password);
			}, function () {
				if (navigator.credentials && window.PasswordCredential) {
					auth.saveNavigatorCredentials(username, password, function () {
						console.log("Credentials saved to navigator");
					}, function () {
						auth.saveLocalstorageCredentials(username, password);
					});
				} else {
					auth.saveLocalstorageCredentials(username, password);
				}
			});

		} else {
			if (navigator.credentials && window.PasswordCredential) {
				auth.saveNavigatorCredentials(username, password, function () {
					console.log("Credentials saved to navigator");
				}, function () {
					auth.saveLocalstorageCredentials(username, password);
				});
			} else {
				auth.saveLocalstorageCredentials(username, password);
			}
		}
	},

	saveTouchCredentials: function (username, password) {
		if (window.plugins.touchid) {
			window.plugins.touchid.isAvailable(function () {
				dialog.confirm({
					title: "Save Password",
					message: "Do you want to store your password in the keychain to use TouchID to sign in?",
				}).then(function (btnIndex) {
					if (btnIndex == 1) {

						var credentials = JSON.stringify({
							u: username,
							p: password,
						});

						window.plugins.touchid.save(auth.endpoint, credentials, "", function () {
							//alert("Password saved");
							console.log("Password saved to keychain");
						});

					}
				});
				return true;
			});
			return false;
		} else {
			return false;
		}
	},

	getCredentials: function () {

		// Return if there is a PWA update ongoing
		if (window.appIsUpdating) {
			return;
		}

		if (navigator.credentials && window.PasswordCredential) {
			navigator.credentials.get({ password: true }).then(function (c) {
				if (c) {
					auth.fillCredentials(c.id, c.password);
				}
			}).catch(function (e) {
				var credentials = utils.lsGet('auth');
				if (credentials) {
					auth.fillCredentials(credentials.username, credentials.password);
				}
			});
		} else {
			var credentials = utils.lsGet('auth');
			if (credentials) {
				auth.fillCredentials(credentials.username, credentials.password);
			}
		}
	},

	fillCredentials: function (username, password) {
		$('#username').val(username);
		$('#password').val(password);
	},

	saveNavigatorCredentials: function (username, password, success, fail) {
		var c = new PasswordCredential({ name: username, id: username, password: password });
		navigator.credentials.store(c).then(function () {
			// console.log("Navigator credentials saved");
			if (typeof (success) == 'function') {
				success();
			}
		}).catch(function () {
			// console.log("Navigator credentials not saved");
			if (typeof (fail) == 'function') {
				fail();
			}
		});
	},

	saveLocalstorageCredentials: function (username, password) {

		console.log("Falling back to localstorage credentials save");

		var credentials = utils.lsGet('auth');
		if (credentials) {
			if (credentials.username == username && credentials.password == password) {
				return;
			}
		}

		dialog.confirm({
			title: 'SAVE CREDENTIALS',
			message: 'Do you want to save your credentials?',
		}).then(function (btnIndex) {
			if (btnIndex == 1) {
				utils.lsSet('auth', { username: username, password: password });
			}
		});
	},

	deauth: function () {

		var deauthPromises = [
			//auth.db.destroy(),
			auth.db.removeItem('user')
		];

		Promise.all(deauthPromises).then(function () {

			auth.user = null;
			auth.init();

		}).catch(function (error) {

			console.log(error);
			auth.init();

		});


	},

}
