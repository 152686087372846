/***** BOF API CALLS *****/

function postData(action, request, callback, failure) {
  $.post({
    url: auth.endpoint,
    data: request,
    success: function (response) {
      if (typeof (callback) == 'function') {
        callback(response);
      }
    },
    headers: {
      ACTION: action,
      TOKEN: (auth.user) ? auth.user.token : null
    }
  }).fail(function (e) {
    if (typeof (failure) == 'function') {
      failure(e);
    }
  });
}



function getData(action, callback, failure) {
  $.get({
    url: auth.endpoint,
    success: function (data) {
      if (callback) {
        callback(data);
      }
    },
    headers: {
      ACTION: action,
      TOKEN: (auth.user) ? auth.user.token : null
    }
  }).fail(function (e) {
    if (typeof (failure) == 'function') {
      failure(e);
    }
  });
}

/***** EOF API CALLS *****/



/***** BOF PWA UTILS *****/

function isStandalone() {
  if ((window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://')) {
    return true;
  } else {
    return false;
  }
}

/***** EOF PWA UTILS *****/



/***** BOF TIME UTILS *****/

function getTS() {

  // return moment().format("X");

  var msTS = new Date().getTime();
  return Math.floor(msTS / 1000);
}

function formatDate(timestamp) {
  return moment(timestamp, 'X').format('YYYY/MM/DD');
}

function formatTime(timestamp) {
  //return moment(timestamp, 'X').format('YYYY/MM/DD HH:mm');
  return moment(timestamp, 'X').calendar();
}

function formatDuration(duration, humanize) {
  if (humanize) {
    return moment.duration(duration, "seconds").humanize(true);
  } else {

    var duration = moment.duration(duration, "seconds");
    return String(duration.get('minutes')).padStart(2, '0') + ':' + String(duration.get('seconds')).padStart(2, '0');
  }
}

/***** EOF TIME UTILS *****/



/***** BOF STRINGS & ARRAYS UTILS *****/

function decodeSvgId(id) {
  id = id.replace(/_x(.*?)_/g, '%$1'); // replace illustrator prseudo-urlencoded strings with real urlencoded strings
  id = id.split('_')[0]; // Remove illustrator layer suffixes
  return decodeURIComponent(id); // return urldecoded string
}

function inArray(needle, haystack) {
  var result = $.inArray(needle, haystack);
  if (result >= 0) {
    return true;
  } else {
    return false;
  }
}

function linkUrls(text) {
  return linkifyStr(text, {
    className: 'linkified',
  });
}


function suggestPassword(length) {

  // Validation regexp
  // ^(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[|!£$%&=?^*@#,.;:_\+\-\/\(\)\[\]\{\}]).*$

  if (!length || length < 8) { length = 8 }

  var keyspace = [
    "|!£$%&=?^*@#,.;:_+-/()[]{}",
    "abcdefghijklmnopqrstuvwxyz",
    "0123456789",
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  ];


  var buf = new Uint8Array(length);
  window.crypto.getRandomValues(buf);

  var password = "";

  for (i = 0; i <= length - 1; i++) {

    // Char list in keyspace
    var listIndex = i % keyspace.length;
    // Char list length 
    var listLength = keyspace[listIndex].length;
    // Random char index in list
    var charIndex = buf[i] % listLength;
    // The char
    var char = keyspace[listIndex][charIndex];

    password += char;

  }

  // Simple chars shuffle
  password.split('').sort(function () { return 0.5 - Math.random() }).join('');

  return password;

}

/***** BOF STRINGS & ARRAYS UTILS *****/



/***** BOF LOCALSTORAGE UTILS *****/

function lsSet(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
  return JSON.stringify(value);
}

//function lsGet(key) {
//  var value = localStorage.getItem(key);
//  if (value === null) {
//    return null;
//  } else {
//    return JSON.parse(value);
//  }
//}

function lsGet(key) {
  var value = localStorage.getItem(key);
  try {
    value = JSON.parse(value);
    return value;
  } catch (e) {
    //console.log(e);
    return null;
  }
}

/***** EOF LOCALSTORAGE UTILS *****/




/***** BOF JSRENDER UTILS *****/

function getTmpl(tmpl, data, target, callback) {

  var extensions = {

    formatDate: function (timestamp) {
      return formatDate(timestamp);
    },

    formatTime: function (timestamp) {
      return formatTime(timestamp);
    },

    formatDuration: function (duration, humanize) {
      return formatDuration(duration, humanize);
    },

    linkUrls: function (text) {
      return linkUrls(text);
    },

    getTS: function () {
      return getTS();
    },

    parseInt: function (value) {
      return parseInt(value);
    }
  }

  if (target) {
    $(target).addClass('loading');
  }

  if (typeof (config) != 'undefined') {
    data.config = config || {};
  }

  if (typeof (auth) != 'undefined') {
    data.auth = auth || {};
  }


  if (typeof (assets) != 'undefined') {
    data.assets = assets.assets;
    data.localFSRoot = assets.localFSRoot || null;
  }



  if (typeof (tmpl) === 'object') {

    var tmplPromises = [];
    var tmplContents = {};

    tmpl.forEach(function (t) {
      tmplPromises.push(new Promise(function (resolve) {

        console.log("GET", t);
        $.get(t + ".html", function (res) {
          tmplContents[t] = res;
          resolve();
        });
      }));
    });

    Promise.all(tmplPromises).then(function () {

      // LAST TEMPLATE IS MAIN
      var mainTmpl = tmpl[tmpl.length - 1];

      // console.log("MAIN TEMPLATE", mainTmpl);
      // console.log("TMPL CONTENTS", tmplContents);

      $.templates(tmplContents);

      console.log("DATA", data);

      var htmlOutput = $.templates[mainTmpl].render(data, extensions);

      if (target) {

        $(target).html(htmlOutput);

        lockImages();

        $(target).removeClass('loading');

        if (typeof localizeUI == 'function') {
          localizeUI();
        }

        if (callback) {
          callback();
        }
      } else {

        if (callback) {
          callback(tmplContents);
        }

      }

    });

  } else {

    $.get(tmpl + ".html", function (res) {

      // console.log("SINGLE TEMPLATE", tmpl);

      var tmpl = $.templates(res);
      var htmlOutput = tmpl.render(data, extensions);

      if (target) {
        $(target).html(htmlOutput);

        lockImages();

        $(target).removeClass('loading');

        if (typeof localizeUI == 'function') {
          localizeUI();
        }

        if (callback) {
          callback();
        }
      } else {
        if (callback) {
          callback(htmlOutput);
        }
      }

    });

  }
}

/***** EOF JSRENDER UTILS *****/



/***** BOF FORM UTILS *****/

function validateForm(selector) {

  var valid = true;
  $(selector + ' input, ' + selector + ' select, ' + selector + ' textarea').each(function () {
    console.log(this.name + " VALID:", this.validity.valid);
    if (!this.validity.valid) {
      this.reportValidity();
      valid = false;
      return false;
    }
  });
  return valid;

}

/***** BOF FORM UTILS *****/





/***** BOF DOM UTILS *****/

function lockImages() {
  $('img').off('contextmenu');
  $('img').on('contextmenu', function (e) {
    e.preventDefault();
    return false;
  });
}

function rebindLinks() {

  if (typeof (cordova) == 'undefined') {
    return;
  }

  $('a').off();
  $('a').on({
    click: function (e) {

      e.preventDefault();

      var href = $(this).attr('href');

      // If electron
      try {

        require("electron").shell.openExternal(href);
        //console.log("Link opened with system browser by electron");

      } catch (e) {

        cordova.InAppBrowser.open(href, '_system');
        //console.log("Link opened with InappBrowser");

      }
    }
  });
}

/***** EOF DOM UTILS *****/





/***** BOF IFRAMES UTILS *****/

function adaptSlide(selector, maxW, maxH) {

  var scale = 1;

  var marginW = 'auto';
  var marginH = 'auto';

  var w = window.innerWidth;
  var h = window.innerHeight;

  var ratioW = w / maxW;
  var ratioH = h / maxH;

  console.log("ratioW", ratioW);
  console.log("ratioH", ratioH);


  if (w < maxW || h < maxH) {

    if (ratioW >= ratioH) {
      scale = ratioH;
    } else {
      scale = ratioW;
    }


  } else {

    if (ratioW >= ratioH) {
      scale = ratioH;
    } else {
      scale = ratioW;
    }

  }

  marginW = (w - (maxW * scale)) / 2;
  marginH = (h - (maxH * scale)) / 2;

  $(selector).css('transform', 'scale(' + scale + ')');
  $(selector).css('margin', marginH + 'px ' + marginW + 'px');


}

/***** EOF IFRAMES UTILS *****/
