dialog = {
	
	alert: function(content, buttons){
		return new Promise(function(resolve, reject){
			// var id = 'dialog-'+(new Date).getTime();
			var dialog = '<div class="dialog-background">';
				dialog += '<div class="dialog dialog-confirm">';
					if(typeof(content)=='object'){
						dialog += '<div class="dialog-title">';
						dialog += content.title?content.title:'ALERT';
						dialog += '</div>';
					}
					dialog += '<div class="dialog-message">';
					dialog += (typeof(content)=='object')?content.message:content;
					dialog += '</div>';
					dialog += '<div class="dialog-buttons">';
					dialog += '<div class="dialog-button">OK</div>';
					dialog += '</div>';
				dialog += '</div>';
			dialog += '</div>';
			$('body').append(dialog);
			$('.dialog-button').off();
			$('.dialog-button').on({
				click: function(){
					$('.dialog-background').remove();
					resolve();
				}
			});
		});
	},
	
	confirm: function(content, buttons){
		return new Promise(function(resolve, reject){
			// var id = 'dialog-'+(new Date).getTime();
			var dialog = '<div class="dialog-background">';
				dialog += '<div class="dialog dialog-confirm">';
					if(typeof(content)=='object'){
						dialog += '<div class="dialog-title">';
						dialog += content.title?content.title:'CONFIRM';
						dialog += '</div>';
					}
					dialog += '<div class="dialog-message">';
					dialog += (typeof(content)=='object')?content.message:content;
					dialog += '</div>';
					dialog += '<div class="dialog-buttons">';
					if(typeof(buttons)=='object'){
						buttons.forEach(function(txt, index){
							dialog += '<div class="dialog-button" data-index="'+index+'">'+txt+'</div>';
						});
					} else {
						dialog += '<div class="dialog-button" data-index="0">CANCEL</div>';
						dialog += '<div class="dialog-button" data-index="1">OK</div>';
					}
					dialog += '</div>';
				dialog += '</div>';
			dialog += '</div>';
			$('body').append(dialog);
			$('.dialog-button').off();
			$('.dialog-button').on({
				click: function(){
					$('.dialog-background').remove();
					resolve(parseInt($(this).data('index')));
				}
			});
		});
	}	
	
}
