// UI langs strings

LANG = {
	'it': {
		'moment_date_format': 'DD/MM/YYYY',
		'moment_time_format': 'HH:mm',
		'moment_datetime_format': 'DD/MM/YYYY HH:mm',
		'updatepwa': "L'app si sta aggiornando, attendi prego ...",
		'nopwasafari': "Copia il link e apri questa pagina<br>su Safari per installare l'app.",
		'nopwachrome': "Copia il link e apri questa pagina<br>su Chrome per installare l'app.",
		'copylink': "COPIA LINK",
		'alertcopylink': "Link è stato copiato.",
		'installpwaios1': "Per installare l'app sul tuo dispositivo clicca su:",
		'installpwaios2': "dopo scrolla verso il basso e clicca su:",
		'addhomescreen': "Aggiungi alla schermata Home",
	},

	'en': {
		'moment_date_format': 'MM/DD/YYYY',
		'moment_time_format': 'HH:mm',
		'moment_datetime_format': 'MM/DD/YYYY HH:mm',
		'updatepwa': "App is updating, please wait...",
		'nopwasafari': "Please, copy link and open this page in Safari<br />to install the app.",
		'nopwachrome': "Please, copy link and open this page in Chrome<br />to install the app.",
		'copylink': "COPY LINK",
		'alertcopylink': "Link has been copied.",
		'installpwaios1': "To install the app on your device click on:",
		'installpwaios2': "then scroll down and click on:",
		'addhomescreen': "Add to Home Screen",
	}
}

if (config.lang) {
	var lang = LANG[config.lang];
}

function localizeUI(parent) {
	$('[data-lang]').each(function () {
		$(this).html(lang[$(this).attr('data-lang')]);
	});
}
