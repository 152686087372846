/*
 * @license
 * Your First PWA Codelab (https://g.co/codelabs/pwa)
 * Copyright 2019 Google Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License
 */
'use strict';


let deferredInstallPrompt = null;

window.addEventListener('beforeinstallprompt', saveBeforeInstallPromptEvent);

/**
 * Event handler for beforeinstallprompt event.
 *   Saves the event & shows install button.
 *
 * @param {Event} evt
 */
function saveBeforeInstallPromptEvent(evt) {

	evt.preventDefault();

	console.log("saveBeforeInstallPromptEvent");
	deferredInstallPrompt = evt;
}


/**
 * Event handler for butInstall - Does the PWA installation.
 *
 * @param {Event} evt
 */
function installPWA(evt) {

	if (!deferredInstallPrompt) {
		dialog.alert({
			title: "App già installata",
			message: "Sembra che l'app sia già installata su questo dispositivo!"
		});
		return;
	}

	deferredInstallPrompt.prompt();
	// Hide the install button, it can't be called twice.
	// evt.srcElement.setAttribute('hidden', true);

	// Follow what the user has done with the prompt.

	//	deferredInstallPrompt.userChoice.then(function(choiceResult) {
	//		console.log(choiceResult.outcome);
	//		if(choiceResult.outcome == 'dismissed') {
	//			console.log('User cancelled home screen install');
	//		} else {
	//		
	//			console.log('User added to home screen');
	//			
	//			location.reload();
	//		}
	//		// We no longer need the prompt.  Clear it up.
	//		deferredInstallPrompt = null;
	//	
	//	});

}




var pwamql = window.matchMedia('(display-mode: standalone), (display-mode: fullscreen)')
var browser = bowser.getParser(window.navigator.userAgent).parsedResult;

console.log('pwamql', pwamql);
console.log('bowser', browser);

if (browser.os.name == "iOS" && parseFloat(browser.os.version) < 13) {
	console.log('NO INSTALL');
} else {

	window.addEventListener('appinstalled', function () {
		console.log("INSTALLED EVENT");
		// Clear the deferredPrompt so it can be garbage collected
		deferredInstallPrompt = null;

		$('#btnInstallPWA').html("L'App è stata installata correttamente.");
	});

	pwamql.addEventListener("change", function (e) {
		console.log("DISPLAY MODE CHANGED", e);
		if (e.matches) {
			location.reload();
		}
	});
}