card = {

	checkLogin: false,

	init: function () {

		auth.db.getItem("user").then(function (user) {

			if (user != null) {
				console.log("found user", user);

				auth.user = user;

				$('#boxLoader, .infoLoader').hide();

				if (typeof (auth.callback) == 'function') {
					card.loadDati();
				}
			} else {

				card.login();

			}

		});

	},

	login: function () {

		card.getAziende().then(function (result) {

			console.log('result', result);

			$('#boxLoader, .infoLoader').hide();

			getTmpl('templates/startCard', result, '#main', function () {

				if (isStandalone()) {
					$('#boxPWA, #boxPopupInfoInstall').hide();
					$('#signInForm').show();
				} else {
					if (browser.os.name == "iOS" && parseFloat(browser.os.version) < 13) {
						$('#infoPopupInfoInstalleriOS, #boxPWA .continueBrowser:first').hide();
						$('#infoOldDeviceIOS').show();

						continueBrowser();
					} else {
						installScreen();
					}
				}

				card.bindCheckLogin();

			});

		});

	},

	bindCheckLogin: function () {

		$('#checkLogin').off();
		$('#checkLogin').on({

			click: function () {

				$('#signInForm').addClass('loading');

				var nome = $('#nome').val();
				var cognome = $('#cognome').val();
				var matricola = $('#matricola').val();
				var azienda = $('#azienda').val();

				postData('checkUser', {
					nome: nome,
					cognome: cognome,
					matricola: matricola,
					azienda: azienda,
				}, function (response) {

					console.log('response', response);

					if (response.status == 'OK') {
						auth.refresh(response.user, function () {
							card.checkLogin = true;
							card.loadDati();
						});
					} else {
						dialog.alert(response.message).then(function () {
							$('#signInForm').removeClass('loading');
						});
					}
				});

			}

		});
	},

	loadDati: function () {

		getTmpl('templates/card', auth.user, '#main', function () {

			console.log('TEMPLATE CARD CARICATO');

			setTimeout(function () {
				if (!card.checkLogin && navigator.onLine) {

					postData('checkUser', {
						nome: auth.user.nome,
						cognome: auth.user.cognome,
						matricola: auth.user.matricola,
						azienda: auth.user.azienda,
					}, function (response) {

						console.log('response', response);

						if (response.status != 'OK') {
							dialog.alert('I dati sono stati aggiornati e non corrispondono con quelli salvati.').then(function () {

								card.returnLogin();

							});
						}

					});

				}
			}, 500);

			$('#logo-noe img').off();
			$('#logo-noe img').on({
				click: function () {
					window.open('https://www.noe.fi.it/', '_blank');
				}
			});

			$('#logout').off();
			$('#logout').on({
				click: function () {

					dialog.confirm({
						title: "Logout",
						message: 'Confermi di voler uscire da "CRAL Menarini" ?',
					}).then(function (btnIndex) {
						if (btnIndex == 1) {

							$('#boxCard').remove();
							$('.infoLoader[data-loader="logout"]').show();
							$('#boxLoader').fadeIn();

							card.returnLogin();

						}
					});

				}
			});

		})
	},


	returnLogin: function () {

		auth.db.removeItem('user');
		auth.user = null;

		card.getAziende().then(function (result) {

			console.log('result', result);

			$('#boxLoader, .infoLoader').hide();

			getTmpl('templates/startCard', result, '#main', function () {

				$('#boxPWA').hide();
				$('#signInForm').show();

				card.bindCheckLogin();

			});

		});

	},


	getAziende: function () {
		return new Promise(function (resolve, reject) {

			postData('getAziende', {}, function (response) {

				resolve(response);

			})
		});
	}

}