assets = {

	// Flag used to recognize the storage type

	assetsDebug: false,
	assetsError: false,

	flowID: null,
	downloadsize: 0,
	downloaded: 0,
	// download: new Event('download'),
	storage: null,

	localFSRoot: null,

	// Used to emulate network failure
	emulateNetworkFailure: false,

	getLocalFS: function () {

		return new Promise(function (resolve) {

			assets.storage = localforage.createInstance({
				name: "IDBS"
			});

			assets.localFSRoot = "IDBS/";
			resolve();

		});

	},

	resetStorage: function () {
		return new Promise(function (resolve, reject) {

			// Destroy and reinit indexedDB
			if (assets.storage) {
				localforage.dropInstance({
					name: "IDBS"
				}).then(function () {
					resolve();
				});
			} else {
				resolve();
			}

		});
	},

	getAsset: function (url, path, assetSize) {
		return new Promise(function (resolve, reject) {

			if (assets.emulateNetworkFailure) {
				reject("FAILED TO DOWNLOAD " + url);
				return;
			}

			var req = new XMLHttpRequest();

			req.open('GET', url + "?token=" + auth.user.token, true);
			req.responseType = 'blob';

			req.onerror = function () {
				assets.assetsError = true;
				console.log("REQUEST FAILED:", this.status);
				reject("FAILED TO DOWNLOAD " + url);
			};

			var previousProgress = 0;
			var downloadChunk = 0;

			req.onprogress = function (event) {
				// console.log("FILE PROGRESS: " + downloadChunk + "/" + event.total);
				downloadChunk = event.loaded - previousProgress;
				previousProgress = event.loaded;
				assets.downloaded = assets.downloaded + downloadChunk;
				$(document).trigger('assets.download', [{ flowID: assets.flowID, downloaded: assets.downloaded, downloadsize: assets.downloadsize }]);
			};
			req.onload = function (r) {
				// console.log(req.response);
				assets.storage.setItem(path, {
					ts: (new Date().getTime()),
					data: req.response,
				}).then(function () {
					resolve(path);
				});
			};

			req.send();

		});
	},

	savePackage: function (assetsList, callback) {
		assets.downloadList = assetsList;
		assets.serializeDownloads(0, callback);
	},

	download: function (asset, callback) {

		var previousProgress = 0;
		var downloadChunk = 0;

		assets.storage.getItem(asset.path).then(function (item) {

			var mustUpdate = false;
			if (item) {
				if (item.ts < asset.expired) {
					mustUpdate = true;
				} else {
					mustUpdate = false;
				}
			} else {
				mustUpdate = true;
			}

			if (assets.assetsDebug) {
				mustUpdate = true;
				// console.log("FORCE UPDATING [DEBUG]" + asset.path);
			}

			if (mustUpdate) {
				// console.log("FILE REMOVED");
				assets.getAsset(asset.url, asset.path, asset.size).then(function () {
					if (typeof callback == "function") {
						callback();
					}
				}).catch(function (e) {
					ons.notification.alert({
						title: "DOWNLOAD ERROR",
						message: "There was a problem downloading an asset, please check your intenet connection and retry",
					}).then(function () {
						assets.download(asset, callback);
					});
				});

			} else {

				if (asset.size) {
					assets.downloaded = assets.downloaded + asset.size;
					$(document).trigger('assets.download', [{ flowID: assets.flowID, downloaded: assets.downloaded, downloadsize: assets.downloadsize }]);
				}

				if (typeof callback == "function") {
					callback();
				}

			}


		});
	},

	serializeDownloads: function (index, callback) {
		if (assets.downloadList.length) {
			var asset = assets.downloadList[index];
			// console.log("CHECKING", assets.downloadList[index].path);
			assets.download(asset, function () {
				// console.log("OK", assets.downloadList[index].path);
				index++;
				if (assets.downloadList.length > index) {
					assets.serializeDownloads(index, callback);
				} else {
					assets.downloadList = [];
					if (typeof callback == "function") {
						callback();
					}
				}
			});
		} else {
			if (typeof callback == "function") {
				callback();
			}
		}
	},

	getPackage: function (flowID) {

		return new Promise(function (resolve, reject) {

			assets.flowID = flowID;

			$('.downloadProgress').html('Checking assets...').show();
			postData("getPackage", {
				id: flowID
			}, function (response) {

				console.log("FILES TO DOWNLOAD", response.files);

				assets.downloadsize = 0;
				assets.downloaded = 0;
				response.files.forEach(function (file) {
					if (file.size) {
						assets.downloadsize = assets.downloadsize + file.size;
					}
				});

				console.log("TOTAL DOWNLOAD SIZE", assets.downloadsize);


				assets.savePackage(response.files, function () {

					// console.log("Package saved.");
					$('.downloadProgress').html('').hide();

					resolve();

				});


			});

		});

	},

	readFile: function (key) {
		return new Promise(function (resolve) {
			assets.storage.getItem(key).then(function (value) {
				resolve(value);
			});
		});
	},

	writeFile: function (key, value) {
		return new Promise(function (resolve) {
			assets.storage.setItem(key, value).then(function () {
				resolve(value);
			});
		});
	},
};
